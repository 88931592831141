import { Container } from "./styles";

export default function Footer() {
  return (
    <Container>
     <h1>
        © 2025 Susana Natividade
        <br />
        All Rights Reserved
      </h1>
      <div className="separator"></div>
    </Container>
  );
}
